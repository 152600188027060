<template>
    <div>
        <aktiv-dialog :dialog.sync="aktivDialog" :personen="filteredTeilnehmer" v-on:accept="toggleAll()" :settings="toggleSetting"></aktiv-dialog>
        <add-praesent :dialog.sync="newPrasentDialog" :sichtbarkeiten.sync="sichtbarkeiten" v-on:filterPool="filterListPool" v-show="newPrasentDialog" :aktionsId="aktionId"></add-praesent>
        <loading :dialog="loading"></loading>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar color="accent">
                    <v-btn icon dark @click.native="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title class="white--text" v-if="aktion">{{aktion.name}}</v-toolbar-title>
                </v-toolbar>
                <v-container fluid>
                    <v-card>
                          <v-row class="pl-2 pr-2 pt-2">
                            <v-col cols="2">
                              <v-text-field label="Vorname"
                                            filled
                                            clearable
                                            @change="filerterAktionsPersonenbyText"
                                            v-model="filter.vorname"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                              <v-text-field label="Nachname"
                                            filled
                                            clearable
                                            @change="filerterAktionsPersonenbyText"
                                            v-model="filter.nachname"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                              <v-text-field label="Firma"
                                            filled
                                            clearable
                                            @change="filerterAktionsPersonenbyText"
                                            v-model="filter.firma"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                              <v-text-field label="E-mail"
                                            filled
                                            clearable
                                            @change="filerterAktionsPersonenbyText"
                                            v-model="filter.email"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                              <v-text-field label="Ort"
                                            filled
                                            clearable
                                            @change="filerterAktionsPersonenbyText"
                                            v-model="filter.ort"></v-text-field>
                            </v-col>
                          </v-row>
                        <v-divider></v-divider>
                      <v-row class="pl-2 pr-2 pt-2">
                        <v-col cols="2">
                          <v-radio-group v-model="filterActive" @change="filterAktionsPersonen('')" :mandatory="true">
                            <v-radio light label="Nur Ausgewählte anzeigen" :value="1"></v-radio>
                            <v-radio light label="Nur nicht Ausgewählte anzeigen" :value="2"></v-radio>
                            <v-radio light label="Alle anzeigen" :value="3"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="2">
                          <v-checkbox text label="Bearbeiter anzeigen" :value="true" v-model="showBearbeiter" class="mt-3" small color="accent"></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-card>
                </v-container>
                <v-data-table :headers="aktionsteilnehmerHeaders"
                              :items="filteredTeilnehmer"
                              :expanded.sync="expanded"
                              item-key="person.id"
                              multi-sort
                              >
                  <template v-slot:header.aktiv="header">
                    {{header.text}}
                    <v-btn text icon small><v-icon color="green" @click.native="startAktivDialog(true, header.herkunft, header.text)">mdi-check-circle-outline</v-icon></v-btn>
                    <v-btn text icon small><v-icon color="red" @click.native="startAktivDialog(false, header.herkunft, header.text)">mdi-close-circle-outline</v-icon></v-btn>
                  </template>
<!--                    <template v-slot:header="{ props:{ headers } }">-->
<!--                      <thead>-->
<!--                        <tr>-->
<!--                            <th v-for="header in headers"-->
<!--                                :key="header.text"-->
<!--                                :class="['column sortable', pagination.sortDesc ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"-->
<!--                                 @click="changeSort(header.value)"-->
<!--                            >-->
<!--                                <div v-if="header.text == 'Aktiv'" style="text-align:left;">-->
<!--                                    {{header.text}}-->
<!--                                    <v-btn text icon small><v-icon color="green" @click.native="startAktivDialog(true, header.herkunft, header.text)">mdi-check-circle-outline</v-icon></v-btn>-->
<!--                                    <v-btn text icon small><v-icon color="red" @click.native="startAktivDialog(false, header.herkunft, header.text)">mdi-close-circle-outline</v-icon></v-btn>-->
<!--                                </div>-->
<!--                                <div v-else-if="header.herkunft == 3" style="text-align:left;">-->
<!--                                    {{header.text}}-->
<!--                                    <v-btn text icon small><v-icon color="green" @click.native="startAktivDialog(true, header.herkunft, header.text)">mdi-check-circle-outline</v-icon></v-btn>-->
<!--                                    <v-btn text icon small><v-icon color="red" @click.native="startAktivDialog(false, header.herkunft, header.text)">mdi-close-circle-outline</v-icon></v-btn>-->
<!--                                </div>-->
<!--                                <div v-else="!(header.text == 'Aktiv') && header.herkunft != 3" style="text-align:left">{{ header.text }}</div>-->
<!--                            </th>-->
<!--                        </tr>-->
<!--                      </thead>-->
<!--                    </template>-->
                  <template v-slot:item="props">
                    <tr>
                      <template v-for="item in filterSichtbakreiten()">
                        <template v-if="item.herkunft == 0">
                          <td v-if="item.objectName == 'aktiv'">
                            <input :id="(props.item.person.id + '-Aktiv')" type="checkbox" v-model="props.item.isParticipant" @change="addParticipant(props.item, props.item.isParticipant, (props.item.person.id + 'labelAktiv'))" :disabled="props.item.isReadOnly" />
                            <label v-show="showBearbeiter" :id="props.item.person.id + 'labelAktiv'" :for="(props.item.person.id + '-Aktiv')">&nbsp;{{props.item.lastSetter}}</label>
                          </td>
                            <td v-else-if="item.objectName == 'perPost'">
                              <input :id="(props.item.person.id + '-perPost')" type="checkbox" v-model="props.item.person.perPost" @change="updatePerPost(props.item.person)" />
                            </td>
                            <td v-else @click="expand(props.item); getLogs(props.item)">
                              {{ props.item.person[item.objectName]}}
                            </td>
                        </template>
                        <td v-else-if="item.herkunft == 1" @click="expand(props.item); getLogs(props.item)">{{ props.item.person.firma[item.objectName]}}</td>
                        <template v-else-if="item.herkunft == 2">
                          <td @click="expand(props.item); getLogs(props.item)">
                            <span v-if="item.displayName == 'Person' && props.item.person.land != null">{{ props.item.person.land[item.objectName]}}</span>
                            <span v-else-if="item.displayName == 'Firma' && props.item.person.firma != null && props.item.person.firma.land != null">{{ props.item.person.firma.land[item.objectName]}}</span>
                          </td>
                        </template>
                        <td v-else="item.herkunft == 3">
                          <template v-for="praesent in filterPraesent(props.item.praesentzuweisungen, item.objectName )">
                            <input :key="props.item.person.id + '-'+ item.objectName" :id="props.item.person.id + '-'+ item.objectName" type="checkbox" v-model="praesent.isAktiv" @change="UpdatePraesentZuweisung(props.item, praesent, ('label-' + props.item.person.id + '-'+ item.objectName))" :disabled="(!props.item.isParticipant || props.item.isReadOnly)" />
                            <label v-show="showBearbeiter" :id="'label-' + props.item.person.id + '-'+ item.objectName" :for="props.item.person.id + '-'+ item.objectName">&nbsp;{{ praesent.lastSetter }}</label>
                          </template>
                        </td>
                      </template>
                    </tr>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-card text>
                            <v-card-text>
                                <v-timeline>
                                    <v-timeline-item v-for="(log, i) in logs"
                                                     :key="i"
                                                     :class="i%2 == 0 ? 'text-right' : 'text-left'"
                                                     color="accent"
                                                     small>
                                        <template v-slot:opposite>
                                            <span class="headline font-weight-bold">{{log.user}}</span>
                                        </template>
                                        <div class="py-3">
                                            <h2 class="headline font-weight-light mb-3">{{formateDate(log.created)}}</h2>
                                            <div>
                                                {{log.meldung}}
                                            </div>
                                        </div>
                                    </v-timeline-item>
                                </v-timeline>
                            </v-card-text>
                        </v-card>
                      </td>
                    </template>
                </v-data-table>
            </v-card>
        </v-dialog>
        <!--SettingsDialog-->
        <v-dialog v-model="settingsdialog"
                  persistent
                  scrollable
                  max-width="1055">
            <v-card raised>
                <v-card-title class="headline accent white--text">Konfiguriere den Spalten Aufbau der Tabelle <v-spacer /><v-btn color="black" @click="saveSettings" dark>Fertig</v-btn></v-card-title>
                <v-card-text v-if="settingsdialog" style="height: 650px;">
                    <v-layout wrap>
                        <v-flex md12 class="mb-4 mt-2">
                          <v-alert
                              border="top"
                              colored-border
                              type="info"
                              color="orange"
                              class="mb-0"
                              elevation="2"
                          >
                           Auf der linken Seite sehen Sie die momentan angezeigten Felder der Aktion. Wählen Sie auf der rechten Seite, das gewünschte Feld aus, welches Sie hinzufügen
                            wollen und übertragen Sie es mit der Pfeiltaste <v-icon small color="black">mdi-arrow-left-thick</v-icon> auf die andere Seite.<br/><br/> Wenn sie ein Feld ausblenden wollen,
                            können Sie es auswählen und von der linken Seite mit Hilfe der Pfeiltaste <v-icon small color="black">mdi-arrow-right-thick</v-icon> zurück auf die rechte Seite bringen.
                            Zusätzlich können Sie die Reihenfolge der Felder über die Pfeiltasten <v-icon small color="black">mdi-arrow-up-thick</v-icon> <v-icon small color="black">mdi-arrow-down-thick</v-icon>
                            regulieren.
                          </v-alert>
                        </v-flex>
                        <v-flex md5>
                            <v-btn-toggle v-model="anzeigeTab" mandatory @change="saveChanges(), filterSichtbarkeit()">
                                <v-btn :value="1">
                                    <v-icon color="red">mdi-tab</v-icon>
                                    Preview
                                </v-btn>
                                <v-btn :value="0">
                                    <v-icon color="green">mdi-download</v-icon>
                                    Download
                                </v-btn>
                            </v-btn-toggle>
                            <v-card>
                                <v-list dense>
                                  <v-list-item dense  v-for="item in sichtbarkeitenAktiv"
                                                :key="item.id"
                                                @click="selectedItem = item"
                                                :class="{'accent-border': selectedItem == item}">
                                    <v-list-item-action dense>
                                      <v-icon v-if="item.herkunft == 0" color="blue">mdi-account</v-icon>
                                      <v-icon v-if="item.herkunft == 1" color="accent">mdi-domain</v-icon>
                                      <v-icon v-if="item.herkunft == 2" color="grey darken-4">
                                        mdi-airplane
                                      </v-icon>
                                      <v-icon v-if="item.herkunft == 3" color="indigo darken-4">mdi-gift</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title dense v-text="item.displayName">
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                            </v-card>
                        </v-flex>
                        <v-flex md2 class="text-middle mt-5">
                          <v-row class="mt-5">
                            <v-col cols="12" class="text-center">
                              <v-btn width="100px" color="black" @click="shiftLeft" :disabled="(((selectedItem != null && selectedItem.isAktiv == true)  ? true : false)  || (!profile.isAdmin && selectedItem != null && selectedItem.isRequire))">
                              <v-icon color="white">mdi-arrow-left-thick</v-icon>
                            </v-btn>
                            </v-col>
                            <v-col cols="12" class="text-center">
                              <v-btn width="100px" color="black" @click="shiftRight" :disabled="(((selectedItem != null && selectedItem.isAktiv == false)  ? true : false) || (!profile.isAdmin && selectedItem != null && selectedItem.isRequire))">
                                <v-icon color="white">mdi-arrow-right-thick</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="12" class="text-center">
                              <v-btn width="100px" color="black" @click="shiftTop" :disabled="(selectedItem != null && selectedItem.isAktiv == false)  ? true : false">
                                <v-icon color="white">mdi-arrow-up-thick</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="12" class="text-center" >
                              <v-btn width="100px" color="black" @click="shiftDown" :disabled="(selectedItem != null && selectedItem.isAktiv == false)  ? true : false">
                                <v-icon color="white">mdi-arrow-down-thick</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-flex>

                        <v-flex md5>
                            <v-btn-toggle v-model="listPool" mandatory @change="filterListPool()">
                                <v-btn text :value="0">
                                    <v-icon color="blue">mdi-account</v-icon>
                                    Person
                                </v-btn>
                                <v-btn text :value="1">
                                    <v-icon color="accent">mdi-domain</v-icon>
                                    Firma
                                </v-btn>
                                <v-btn text :value="3">
                                    <v-icon color="grey darken-4">mdi-airplane</v-icon>
                                    Land
                                </v-btn>
                                <v-btn text :value="2"  v-if="profile.isAdmin">
                                    <v-icon color="indigo darken-4">mdi-gift</v-icon>
                                    Präsent
                                </v-btn>
                            </v-btn-toggle>
                            <v-card>
                                <v-list dense>
                                  <v-list-item v-if="listPool == 2"
                                               :key="-1"
                                               dense>
                                    <v-list-item-action>
                                      <v-icon v-if="!loadPraesent" color="indigo darken-3">mdi-gift</v-icon>
                                      <v-progress-circular v-else
                                                           indeterminate
                                                           color="indigio darken-3"></v-progress-circular>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-btn color="indigo darken-3" @click="newPrasentDialog = true" block dark>Präsent hinzufügen</v-btn>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item v-for="item in sichtbarkeitenInAktiv"
                                               :key="item.id"
                                               dense
                                               :class="{'accent-border': selectedItem == item}"
                                               @click="selectedItem = item">
                                    <v-list-item-title v-text="item.displayName">
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
        <JsonExcel v-if="downloadAktion" class="v-btn accent pa-2 mt-2" style="cursor: pointer;" :data="downloadPersonen">Download Aktion {{downloadAktion}}</JsonExcel>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-alert
              border="top"
              colored-border
              type="info"
              color="orange"
              class="mb-0"
              elevation="2"
              dark
          >
           Konfigurieren Sie die angezeigten Felder einer Tabeller über die <v-icon color="orange">mdi-settings</v-icon> Einstellungen.
          </v-alert>
        </v-col>
          <template v-for="(item) in filteredAktionen">
            <v-col cols="12" md="3">
              <v-card dark>
                <v-card-title class="subHeader" primary-title>
                  {{item.name}}
                  <v-spacer></v-spacer>
                  <v-btn text icon color="orange" @click.stop="showSettings(item.id)">
                    <v-icon large>mdi-settings</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="text-space">
                  <small>Teilnehmer: {{item.teilnehmerzahl}}</small><br />
                  {{item.beschreibung}}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn dark color="primary" @click="getAktionsPersonen(item.id)"><v-icon>mdi-account</v-icon>Teilnehmer</v-btn>
                  <v-btn v-if="profile != null && profile.isAdmin" color="orange" @click="prepareDownload(item.id)"><v-icon >mdi-download</v-icon>DL vorbereiten</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </template>
      </v-row>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import JsonExcel from '../JsonExcel'
    export default {
        data() {
            return {
                person: null,
                preview: true,
                id: null,
                dialog: false,
                settingsdialog: false,
                firma: null,
                sichtbarkeiten: [],
                aufsteigend: true,
                filteredSichtbarkeiten: null,
                sichtbarkeit: null,
                sichtbarkeitenAktiv: [],
                sichtbarkeitenInAktiv: [],
                aktionId: 0,
                listPool: 0,
                anzeigeTab: 1,
                loadPraesent: false,
                logs: [],
                selectedItem: null,
                dummyActive: [],
                showBearbeiter: true,
                table_height: 0,
                expanded: [],
                filter: { vorname: '', nachname: '', firma: '', email: '', ort: '' },
                sortPersonen: { anrede: 0, titel: 0, vorname: 0, nachname: 0, email: 0, telefon: 0, ort: 0, firma: 0, kontaktVon: 0 },
                sortIndex: [],
                filterActive: 3,
                testList: [],
                test: null,
                newPrasentDialog: false,
                aktivDialog: false,
                toggleSetting: { isAktiv: false, herkunft: null, name: '' },
                loading: false,
                pagination: null,

                aktion: null,
                blancoAktion: { id: 0, Aktionsname: '', created: '', basis: [] },
                aktionsPersonen: [],
                filteredAktionsPersonen: [],
                downloadPersonen: [],
                downloadAktion: null,
                filteredTeilnehmer: [],
                aktionsteilnehmerHeaders: []
            }
        },
        components: {
            JsonExcel
        },
        watch: {

        },
        computed: {
            ...mapState(['profile', 'filteredAktionen']),
        },

        beforeMount() {

        },
        mounted() {

        },
        props: [],
        methods: {
          expand(value){
            const item = this.expanded.find(i => i.id === value.id);
            const index = this.expanded.indexOf(item);

            if(index > -1){
              this.expanded.splice(index, 1);
            }
            else{
              this.expanded = [];
              this.expanded.push(value);
            }
          },
            startAktivDialog: function (value, herkunft, name) {
                this.toggleSetting.isAktiv = value;
                this.toggleSetting.herkunft = herkunft;
                this.toggleSetting.name = name;
                this.aktivDialog = true;

            },
            filterPraesent: function (liste, name) {
                // var dummy = [{ praesent: name, isAktiv: false, isReadonly: false, lastSetter: '', praesentzuweisungsId: -1 }];

                if (liste != null && liste.length > 0) {
                    var item = liste.filter(function (element) { return element.praesent.toLowerCase() === name.toLowerCase() });
                    return item;
                }

            },
            filerterAktionsPersonenbyText: function () {
                var data = this.aktionsPersonen;

                if (this.filterActive == 1) {

                    data = data.filter(a => a.isParticipant);
                }
                else if (this.filterActive == 2) {
                    data = data.filter(a => !a.isParticipant);
                }
                else {
                    data = data;
                }

                if (this.filter.vorname != null && this.filter.vorname.length > 0) {

                    data = data.filter(p => (p.person.vorname != null && p.person.vorname.toLowerCase().indexOf(this.filter.vorname.toLowerCase()) != -1));
                }
                if (this.filter.nachname != null && this.filter.nachname.length > 0) {
                    data = data.filter(p => (p.person.nachname != null && p.person.nachname.toLowerCase().indexOf(this.filter.nachname.toLowerCase()) != -1));
                }
                if (this.filter.firma != null && this.filter.firma.length > 0) {
                    data = data.filter(p => (p.person.firma != null && p.person.firma.name.toLowerCase().indexOf(this.filter.firma.toLowerCase()) != -1));
                }
                if (this.filter.email != null && this.filter.email.length > 0) {
                    data = data.filter(p => (p.person.email != null && p.person.email.toLowerCase().indexOf(this.filter.email.toLowerCase()) != -1));
                }
                if (this.filter.ort != null && this.filter.ort.length > 0) {
                    data = data.filter(p => p.person.firma != null && p.person.firma.ort != null && (p.person.firma.ort.toLowerCase().indexOf(this.filter.ort.toLowerCase()) != -1));
                }

                this.filteredTeilnehmer = data;
            },
          filterAktionsPersonen: function (sortBox) {
            var data = this.aktionsPersonen;

            if (this.filterActive == 1) {

              data = data.filter(a => a.isParticipant);
            }
            else if (this.filterActive == 2) {
              data = data.filter(a => !a.isParticipant);
            }
            else {
              data = data;
            }
            this.filteredTeilnehmer = data;
          },
          sortArray: function (list, prop) {
            if (prop == "ort" || prop == "firma") {
              var tempProp = prop == "firma" ? "name" : "ort";

              list.sort(function (a, b) {
                if (a.person.firma[tempProp] == null || a.person.firma[tempProp] == undefined) {
                  a.person.firma[tempProp] = "";
                }
                if (b.person.firma[tempProp] == null || b.person.firma[tempProp] == undefined) {
                  b.person.firma[tempProp] = "";
                }
                return a.person.firma[tempProp].toLowerCase().localeCompare(b.person.firma[tempProp].toLowerCase());
              })
            }
            else {
              list.sort(function (a, b) {
                if (a.person[prop] == null || a.person[prop] == undefined) {
                  a.person[prop] = "";
                }
                if (b.person[prop] == null || b.person[prop] == undefined) {
                  b.person[prop] = "";
                }

                return a.person[prop].toLowerCase().localeCompare(b.person[prop].toLowerCase());
              })
            }

            if (!this.aufsteigend) {
              return list.reverse();
            }
            return list;
          },
            filterSichtbakreiten: function () {
                var items = this.sichtbarkeiten.filter(function (element) { return element.art === 1 && element.isAktiv });

                return items;
            },
            getLogs: function (aktionsperson) {
                this.$http.get('/api/Stammdaten/Logs/' + aktionsperson.person.id + '/' + aktionsperson.aktionsId).then(response => {
                    this.logs = response.data;
                }).catch((error) => console.log(error));
            },
            saveSettings: function () {
                this.saveChanges();
                this.selectedItem = null;
                this.settingsdialog = false;
            },
            saveChanges: function () {
                this.$http.post('api/Stammdaten/SaveSettings', this.sichtbarkeiten).then(response => {
                }).catch((error) => console.log(error))
            },
            getDifferentItems: function (list, prop) {
                var items = [];
                if (prop == "ort" || prop == "firma") {
                    var tempProp = prop == "firma" ? "name" : prop;

                    for (var i = 0; i < list.length; i++) {
                        if (items.indexOf(list[i].person.firma[tempProp].toLowerCase()) == -1) {
                            items.push(list[i].person.firma[tempProp].toLowerCase());
                        }

                    }
                }
                else {
                    for (var i = 0; i < list.length; i++) {
                        if (items.indexOf(list[i].person[prop].toLowerCase()) == -1) {
                            items.push(list[i].person[prop].toLowerCase());
                        }
                    }
                }

                return items;

            },
            shiftTop: function () {
                var currentItem = this.selectedItem;
                var index = this.sichtbarkeitenAktiv.indexOf(currentItem);

                if (currentItem != null && index > 0) {

                    var up = this.sichtbarkeitenAktiv[index];
                    var down = this.sichtbarkeitenAktiv[index - 1];

                    up.sortOrder -= 1;
                    down.sortOrder += 1;

                    this.sichtbarkeitenAktiv.splice(index - 1, 1, up)
                    this.sichtbarkeitenAktiv.splice(index, 1, down)

                    this.selectedItem = up;

                    //this.sichtbarkeitenAktiv = this.sichtbarkeitenAktiv;
                }
                else {
                    //letztes Element
                }

            },
            shiftDown: function () {
                var currentItem = this.selectedItem;
                var index = this.sichtbarkeitenAktiv.indexOf(currentItem);

                if (currentItem != null && index < this.sichtbarkeitenAktiv.length - 1) {
                    var down = this.sichtbarkeitenAktiv[index];
                    var up = this.sichtbarkeitenAktiv[index + 1];

                    up.sortOrder -= 1;
                    down.sortOrder += 1;

                    this.sichtbarkeitenAktiv.splice(index, 1, up)
                    this.sichtbarkeitenAktiv.splice(index + 1, 1, down)

                    this.selectedItem = down;

                    //this.sichtbarkeitenAktiv = this.sichtbarkeitenAktiv;
                }
                else {
                    //Ist erstes Element
                }

            },
            shiftLeft: function () {
                var currentItem = this.selectedItem;
                if (currentItem != null && (!currentItem.isRequire || this.profile.isAdmin)) {
                    currentItem.isAktiv = true;
                    currentItem.sortOrder = this.sichtbarkeitenAktiv.length > 0 ? Math.max.apply(Math, this.sichtbarkeitenAktiv.map(function (o) { return o.sortOrder; })) + 1 : 0;

                    this.sichtbarkeitenAktiv.push(currentItem);

                    for (var i = 0; i < this.sichtbarkeitenInAktiv.length; i++) {
                        if (currentItem.id == this.sichtbarkeitenInAktiv[i].id) {
                            this.sichtbarkeitenInAktiv.splice(i, 1)

                            if (i + 1 <= this.sichtbarkeitenInAktiv.length) {
                                this.selectedItem = this.sichtbarkeitenInAktiv[i]
                            }
                            else {
                                this.selectedItem = null;
                            }
                        }

                    }
                }

            },
            activeFilters: function () {
                var filterString = "";
                for (var i = 0; i < this.sortIndex.length; i++) {

                    if (i == 0) {
                        filterString += this.sortIndex[i];
                    }
                    else if (filterString != null) {
                        filterString += " - " + this.sortIndex[i];
                    }

                }
                return filterString;
            },

            shiftRight: function () {
                var currentItem = this.selectedItem;
                if (currentItem != null && (!currentItem.isRequire || this.profile.isAdmin)) {
                    currentItem.isAktiv = false;
                    currentItem.sortOrder = this.sichtbarkeitenInAktiv.length > 0 ? Math.max.apply(Math, this.sichtbarkeitenInAktiv.map(function (o) { return o.sortOrder; })) + 1 : 0;

                    this.sichtbarkeitenInAktiv.push(currentItem);

                    for (var i = 0; i < this.sichtbarkeitenAktiv.length; i++) {
                        if (currentItem.id == this.sichtbarkeitenAktiv[i].id) {

                            this.sichtbarkeitenAktiv.splice(i, 1)

                            if (i + 1 <= this.sichtbarkeitenAktiv.length) {
                                this.selectedItem = this.sichtbarkeitenAktiv[i]
                            }
                            else {
                                this.selectedItem = null;
                            }
                        }
                    }
                    this.filterSichtbarkeit();
                }

            },
            UpdatePraesentZuweisung: function (aktionsperson, praesent, labelId) {
                praesent.lastSetter = this.profile.email;
                var copy = JSON.parse(JSON.stringify(aktionsperson));
                copy.praesentzuweisungen.splice(0, copy.praesentzuweisungen.length);
                copy.praesentzuweisungen.push(praesent);

                this.$http.post('api/Stammdaten/UpdatePraesentZuweisung', copy).then(response => {
                    var zuweisung = response.data;
                    praesent.praesentzuweisungsId = zuweisung.PraesentzuweisungsId;
                    praesent.isAktiv = zuweisung.isAktiv;
                    praesent.isReadOnly = zuweisung.isReadOnly;

                }).catch((error) => console.log(error));

            },

            UpdatePraesentZuweisungen: function (aktionsperson, praesent, labelId) {
                this.loading = true;
                var label = document.getElementById(labelId);

                label.innerText = this.profile.email;
                var tempAktionsPerson = JSON.parse(JSON.stringify(aktionsperson));

                if (praesent.praesentzuweisungsId < 0) {

                    var toSend = [{ praesent: praesent.praesent, isAktiv: true, isReadonly: false, lastSetter: this.profile.email, praesentzuweisungsId: -2 }];
                    tempAktionsPerson.praesentzuweisungen = toSend;

                }
                else {
                    praesent.lastSetter = this.profile.email;
                    this.$http.post('api/Stammdaten/UpdatePraesentZuweisung', praesent).then(response => {
                        this.loading = false;
                    }).catch((error) => console.log(error));
                }

            },
            toggleAll: function () {
                //aktiv
                this.loading = true;
                var isAktiv = this.toggleSetting.isAktiv;

                if (this.toggleSetting.herkunft != 3) {
                    for (var i = 0; i < this.filteredTeilnehmer.length; i++) {
                        this.filteredTeilnehmer[i].isParticipant = isAktiv;
                        this.filteredTeilnehmer[i].lastSetter = this.profile.email;
                        this.filteredTeilnehmer[i].isAktiv = isAktiv;
                    }

                    var sett = { Aktion: this.aktion, Personen: this.filteredTeilnehmer, isAktiv }
                    this.$http.post('/api/Stammdaten/SetGroupOfPersons', sett).then(response => {
                        this.toggleSetting.setAktiv = false;
                        this.loading = false;
                    }).catch((error) => console.log(error));
                }
                else {
                    //präsente

                    var persons = [];
                    var objectname = this.toggleSetting.name.toLowerCase().split(' ').join('');

                    var teilnehmer = this.filteredTeilnehmer;
                    var aktiveTeilnehmer = teilnehmer.filter(function (element) { return element.isParticipant == true });

                    for (var i = 0; i < aktiveTeilnehmer.length; i++) {
                        var data = aktiveTeilnehmer[i].praesentzuweisungen;
                        var praesent = data.filter(function (element) { return element.praesent == objectname })[0];

                        praesent.isAktiv = isAktiv;
                        praesent.lastSetter = this.profile.email;

                        var copyPerson = JSON.parse(JSON.stringify(this.filteredTeilnehmer[i]));
                        copyPerson.praesentzuweisungen = JSON.parse(JSON.stringify([praesent]));
                        persons.push(copyPerson);

                    }

                    var sett2 = { Aktion: this.aktion, Personen: persons, isAktiv }
                    this.$http.post('api/Stammdaten/UpdatePraesentZuweisungen', sett2).then(response => {
                        this.loading = false;
                    }).catch((error) => console.log(error));

                }

            },
            filterSichtbarkeit: function () {
                var data = [];

                if (this.anzeigeTab == 1) {
                    //Preview
                    data = this.sichtbarkeiten.filter(function (element) { return element.art == 1 });
                }
                else {
                    //Download
                    data = this.sichtbarkeiten.filter(function (element) { return element.art == 0 });
                }

                this.sichtbarkeitenInAktiv = data.filter(function (element) { return !element.isAktiv });
                this.sichtbarkeitenAktiv = data.filter(function (element) { return element.isAktiv });
                this.filterListPool();
            },
            filterListPool: function () {
                var data = this.sichtbarkeiten;

                if (this.anzeigeTab == 1) {
                    //Preview
                    data = this.sichtbarkeiten.filter(function (element) { return element.art == 1 });
                }
                else {
                    //Download
                    data = this.sichtbarkeiten.filter(function (element) { return element.art == 0 });
                }

                switch (this.listPool) {
                    case 0://Person
                        this.sichtbarkeitenInAktiv = data.filter(function (element) { return element.isAktiv == false && element.herkunft == 0 });
                        break;
                    case 1://Firma
                        this.sichtbarkeitenInAktiv = data.filter(function (element) { return element.isAktiv == false && element.herkunft == 1 });
                        break;
                    case 2://Präsent
                        this.sichtbarkeitenInAktiv = data.filter(function (element) { return element.isAktiv == false && element.herkunft == 3 });
                        break;
                    case 3://Land
                        this.sichtbarkeitenInAktiv = data.filter(function (element) { return element.isAktiv == false && element.herkunft == 2 });
                        break;
                }

            },
            formateDate: function (dateString) {
                var date = new Date(dateString);

                var day = date.getDate().toString();
                day = day.length == 1 ? "0" + day : day;

                var month = (date.getMonth() + 1).toString();
                month = month.length == 1 ? "0" + month : month;

                var year = date.getFullYear().toString();
                return day + '.' + month + '.' + year;

            },
            addParticipant: function (item, value, labelId) {
                item.lastSetter = this.profile.email;

                //  var label = document.getElementById(labelId);

                //label.innerText = this.profile.email;

                var mark = {};
                mark.id = item.person.id;
                mark.aktionsID = this.aktion["id"];
                mark.value = value;

                //this.person = this.aktionsPersonen.filter(function (element) { return element.person.id == id })[0];
                this.$http.post('/api/Stammdaten/MarkPerson', mark).then(response => {
                    item.aktionszuweisungsId = response.data;

                }).catch((error) => console.log(error));
            },

            updatePerPost: function (person) {
                this.$http.post('/api/Stammdaten/UpdatePersonPerPost/', person).then(response => {

                }).catch((error) => console.log(error));
            },

           async showSettings(aktionsId) {
                this.aktionId = aktionsId;
                await this.getAktionsSettings(aktionsId);
            },
            checkDummy: function (id, aid) {
                var idAsString = id.toString() + aid.toString();
                var check = this.dummyActive.indexOf(idAsString);
                if (check >= 0) {
                    return true
                }
                else {
                    return false;
                }

            },
            sleep: function (milliseconds) {
                var start = new Date().getTime();
                for (var i = 0; i < 1e7; i++) {
                    if ((new Date().getTime() - start) > milliseconds) {
                        break;
                    }
                }
            },
            prepareDownload: function (id) {
                var aktion = this.filteredAktionen.filter(function (element) { return element.id == id })[0];
                this.$http.get('/api/Stammdaten/AktionspersonenDownload/' + id).then(response => {
                    this.downloadPersonen = response.data;
                    this.downloadAktion = aktion.name;
                }).catch((error) => console.log(error));
            },
          close(){
            this.$store.dispatch('getAktionen');
            this.dialog = false;
            this.loading = false;
          },
            getAktionsPersonen: function (id) {
                this.loading = true;
                this.dummyActive.splice(0, this.dummyActive.length);

                this.$http.get('/api/Stammdaten/AktionsSettings/' + id).then(response => {
                    this.sichtbarkeiten = response.data;
                    this.prepareTable();

                }).catch((error) => console.log(error));

                this.aktion = this.filteredAktionen.filter(function (element) { return element.id == id })[0];
                this.$http.get('/api/Stammdaten/Aktionspersonen/' + id).then(response => {
                    this.aktionsPersonen = response.data;
                    this.filteredTeilnehmer = this.aktionsPersonen
                    this.loading = false;
                    this.dialog = true;
                    return this.aktionsPersonen;

                }).catch((error) => console.log(error));

            },
            async prepareTable() {
                this.aktionsteilnehmerHeaders.splice(0, this.aktionsteilnehmerHeaders.length);
                for (let i = 0; i < this.sichtbarkeiten.length; i++) {
                    if (this.sichtbarkeiten[i].art == 1 && this.sichtbarkeiten[i].isAktiv)
                        if (this.sichtbarkeiten[i].herkunft == 1) {
                            this.aktionsteilnehmerHeaders.push({ text: '(F) ' + this.sichtbarkeiten[i].displayName, value: 'person.firma.' + this.sichtbarkeiten[i].objectName, herkunft: this.sichtbarkeiten[i].herkunft, sortable: true })
                        }
                        else if (this.sichtbarkeiten[i].herkunft == 2)
                        {
                              var preObject = this.sichtbarkeiten[i].displayName == 'Firma' ? 'person.firma.land.' : 'person.land.';
                                this.aktionsteilnehmerHeaders.push({ text: '(Land) ' + this.sichtbarkeiten[i].displayName, value: preObject + this.sichtbarkeiten[i].objectName, herkunft: this.sichtbarkeiten[i].herkunft, sortable: true, })
                        }
                        else {
                          let objectname = this.sichtbarkeiten[i].objectName == 'aktiv' ? 'isParticipant' : 'person.' + this.sichtbarkeiten[i].objectName;
                                this.aktionsteilnehmerHeaders.push({ text: this.sichtbarkeiten[i].displayName, value: objectname, herkunft: this.sichtbarkeiten[i].herkunft, sortable: true, })
                        }

                }
                //this.aktionsteilnehmerHeaders.push({ text: 'Gesetzt von', value: 'lastSetter' });

            },
            saveSetting: function () {
                var data = { AktionsId: this.aktionId, AktionsFeldSichtbarkeit: this.preview ? this.setting : this.downloadSetting };
                this.$http.post('/api/Stammdaten/SetAktionSettings', data).then(response => {
                    this.settingsdialog = false;
                }).catch((error) => console.log(error));

                this.preview = true;
            },
            checkVisiblity: function (name) {
                var sichtbarkeit = this.sichtbarkeiten.filter(function (element) { return element.feldname == name })[0];
                return sichtbarkeit;

            },
            getAktionsSettings: async function (id) {
                await this.$http.get('/api/Stammdaten/AktionsSettings/' + id).then(async response => {
                    this.sichtbarkeiten = response.data;
                    await this.prepareTable();
                    this.filterSichtbarkeit();
                    this.settingsdialog = true;
                }).catch((error) => console.log(error));
            },
        },

    }
</script>
